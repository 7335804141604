
import { defineComponent, PropType } from "vue";
import MenuOptionDropdown from "@/components/atomics/dropdown/MenuOptionDropdown.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import ToggleCheckbox from "../ToggleCheckbox.vue";

interface ActionItem {
  action: () => void;
  icon: string;
  text: string;
  disabled?: boolean;
  isDelete?: boolean;
  type?: string;
  isActBeforeToggle?: boolean;
  toggleValue?: boolean;
}

export default defineComponent({
  name: "ActionDropdown",
  components: {
    MenuOptionDropdown,
    TextButton,
    ToggleCheckbox,
  },
  props: {
    items: {
      type: Array as PropType<Array<ActionItem>>,
      required: true,
    },
  },
  emits: ["action-done"],
  setup() {
    return {};
  },
});
