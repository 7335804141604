
import { defineComponent, PropType, ref } from "vue";
import ActionDropdown from "@/components/atomics/dropdown/ActionDropdown.vue";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";

interface ActionItem {
  action: () => void;
  icon: string;
  text: string;
}

const __default__ = defineComponent({
  name: "ListItemActionDropdown",
  components: {
    ItemInList,
    ActionDropdown,
    TextButton,
  },
  props: {
    headIcon: {
      type: String,
      default: null,
    },
    isAvatarHead: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array as PropType<Array<ActionItem>>,
      required: true,
    },
  },
  setup() {
    const actionDropdown = ref<InstanceType<typeof ActionDropdown>>();
    const openDropdownBtn = ref<InstanceType<typeof TextButton>>();
    const isShowActionDropdown = ref(false);

    const unFocus = (e: FocusEvent) => {
      if (
        (actionDropdown.value?.$el as HTMLElement)?.contains(
          e.relatedTarget as HTMLElement
        )
      )
        isShowActionDropdown.value = true;
      else if (
        (actionDropdown.value?.$el as HTMLElement)?.contains(
          e.target as HTMLElement
        ) &&
        e.relatedTarget == openDropdownBtn.value?.$el
      )
        isShowActionDropdown.value = true;
      else isShowActionDropdown.value = false;
    };

    const focus = () => {
      isShowActionDropdown.value = !isShowActionDropdown.value;
    };

    return {
      isShowActionDropdown,
      focus,
      unFocus,
      actionDropdown,
      openDropdownBtn,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "83f9bdb0": (_ctx.isShowActionDropdown ? 1: 0)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__