
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrimaryDoing",
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      default: "doing",
    },
  },
});
