import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1781fbf9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "item-title" }
const _hoisted_3 = { class: "title-primary" }
const _hoisted_4 = { class: "title-secondary" }
const _hoisted_5 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ActionDropdown = _resolveComponent("ActionDropdown")!
  const _component_ItemInList = _resolveComponent("ItemInList")!

  return (_openBlock(), _createBlock(_component_ItemInList, { class: "list-item-action-dropdown" }, {
    head: _withCtx(() => [
      (_ctx.headIcon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.headIcon,
            alt: "avatar",
            class: _normalizeClass(["mr-1 avatar", { 'bordered-avatar': _ctx.isAvatarHead }])
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _renderSlot(_ctx.$slots, "item-title", {}, () => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title-primary", {}, undefined, true)
          ]),
          _createElementVNode("p", _hoisted_4, [
            _renderSlot(_ctx.$slots, "title-secondary", {}, undefined, true)
          ])
        ])
      ], true),
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ]),
    tail: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_TextButton, {
          class: "option-btn",
          ref: "openDropdownBtn",
          icon: require('icons/more.svg'),
          "is-show-text": false,
          onClick: _ctx.focus,
          onBlur: _ctx.unFocus
        }, null, 8, ["icon", "onClick", "onBlur"]),
        (_ctx.isShowActionDropdown)
          ? (_openBlock(), _createBlock(_component_ActionDropdown, {
              key: 0,
              ref: "actionDropdown",
              tabindex: "0",
              items: _ctx.actions,
              onActionDone: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowActionDropdown = false)),
              onBlur: _ctx.unFocus,
              "indicator-position": "10px"
            }, null, 8, ["items", "onBlur"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}